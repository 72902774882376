import React from "react"
import { Link } from 'gatsby'

import Layout from "../layouts"
import Head from "../components/head"

const NotFoundPage = () => (
  <Layout>
    <Head title="404: Not found" />
    <div class="container" style={{ padding: '60px 0 80px' }}>
      <div className="smallGrid" style={{ textAlign: 'center' }}>
        <h1>404 Page Not Found</h1>
        <p style={{ fontSize: '20px' }}>You just hit a route that doesn&#39;t exist.</p>
        <Link to="/" style={{ fontSize: '20px', color: '#21c6be' }}>Go home</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
